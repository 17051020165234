import styled from 'styled-components'
import theme from './theme'

export const Videos = styled.main`
  padding: ${theme.paddingAround};
  padding-top: 2rem;
  margin: 0 auto;
  .videosContainer {
    margin: ${theme.paddingAround} 0;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(5, 1fr);
    @media screen and (max-width: ${theme.breakpoints.vl}px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: ${theme.breakpoints.lg}px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      grid-template-columns: repeat(2, 1fr);
      margin: 2rem 0;
      gap: 2rem;
    }
    @media screen and (max-width: ${theme.breakpoints.mm}px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .video {
      transition: all 0.3s ease-in-out;
      &:hover {
        color: ${theme.colors.dark};
        cursor: pointer;
        background-color: ${theme.colors.light};
      }
      .textContainer {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        padding: 0.5rem;
        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 0.2rem;
        }
        h3 {
          text-transform: uppercase;
          margin: 0;
        }
        .smallInfo {
          color: ${theme.colors.grey};
        }
        .right {
          .smallInfo {
            text-align: right;
          }
        }
      }
    }
  }
`

export const VideosFilter = styled.div`
  max-width: ${theme.contentWidth};
  margin: 0 auto;
  .mainContainer {
    display: flex;
  }
  .mainButton,
  .resetButton {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    background-color: ${theme.colors.dark};
    color: ${theme.colors.light};
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    height: 35px;
    &:hover {
      filter: invert(1);
    }
  }
  .filters {
    position: absolute;
    top: calc(51px + 2rem + 35px);
    left: 0;
    width: 100vw;
    height: 100vh;
    padding-bottom: ${theme.paddingAround};
    z-index: 9;
    background-color: ${theme.colors.dark};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .filterButton {
      padding: 2rem 0;
      padding-left: 1rem;
      width: 100%;
      text-align: left;
      &:hover {
        background-color: ${theme.colors.light};
        color: ${theme.colors.dark};
      }
      .inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: ${theme.contentWidth};
        margin: 0 auto;
      }
    }
  }
  button {
    text-transform: uppercase;
  }
`
