import React, {useState, useEffect} from 'react'
import * as Styled from '../styles/videos.style.jsx'
import {Link, graphql} from 'gatsby'
import {GatsbyImage, getImage, StaticImage} from 'gatsby-plugin-image'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock'
import Helmet from 'react-helmet'
import _ from 'lodash'

import Footer from '../components/Footer'

var slugify = require('slugify')

function VideosFilter({filterCat, setFilterCat}) {
  const [showFilters, setShowFilters] = useState(false)
  const categories = ['Interviews', 'Clips', 'Vortex', 'Live']

  function escFunction(e) {
    if (e.key === 'Escape') {
      setShowFilters(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', (e) => escFunction(e))
    return document.removeEventListener('keydown', (e) => escFunction(e))
  }, [])

  useEffect(() => {
    if (showFilters) {
      window.scrollTo(0, 0)
      disableBodyScroll(document.querySelector('.filters'))
    } else {
      enableBodyScroll(document.querySelector('.filters'))
      clearAllBodyScrollLocks()
    }
  }, [showFilters])

  return (
    <Styled.VideosFilter>
      <div className="mainContainer">
        <button
          className="mainButton"
          onClick={() => setShowFilters(!showFilters)}
        >
          {filterCat === '' ? 'TOUTES LES VIDEOS' : filterCat}
          <StaticImage src="../images/icons/chevron.svg" alt="arrow" />
        </button>
        {filterCat !== '' && (
          <button
            className="resetButton"
            key="all"
            onClick={() => {
              setFilterCat('')
              setShowFilters(false)
            }}
          >
            X
          </button>
        )}
      </div>
      {showFilters && (
        <div className="filters">
          {categories.map((cat) => (
            <button
              className="filterButton"
              key={cat}
              onClick={() => {
                setFilterCat(cat)
                setShowFilters(false)
              }}
            >
              <div className="inner">{cat}</div>
            </button>
          ))}
        </div>
      )}
    </Styled.VideosFilter>
  )
}

function Videos({data}) {
  const [videos, setVideos] = useState([])
  const [filterCat, setFilterCat] = useState('')

  useEffect(() => {
    setVideos(
      _.orderBy(data.allPrismicListeVideos.nodes[0].data.video, 'year', 'desc')
    )
  }, [data])

  useEffect(() => {
    filterCat === ''
      ? setVideos(
          _.orderBy(
            data.allPrismicListeVideos.nodes[0].data.video,
            'year',
            'desc'
          )
        )
      : setVideos(
          _.orderBy(
            data.allPrismicListeVideos.nodes[0].data.video,
            'year',
            'desc'
          ).filter(
            (video) => video.category.toLowerCase() === filterCat.toLowerCase()
          )
        )
  }, [filterCat, data])

  useEffect(() => {
    return () => {
      enableBodyScroll(document.querySelector('.filters'))
      clearAllBodyScrollLocks()
    }
  }, [])

  return (
    <Styled.Videos>
      <Helmet title="Videos | Jacques" htmlAttributes={{lang: 'fr'}} />
      <VideosFilter filterCat={filterCat} setFilterCat={setFilterCat} />
      <div className="videosContainer">
        {videos.map(({title, category, length, url, year, thumbnail}) => {
          const image = getImage(thumbnail.localFile.childImageSharp)
          const sluggedTitle = slugify(title, {
            lower: true,
            strict: true,
            locale: 'fr'
          })
          return (
            <Link to={`/video/${sluggedTitle}`} className="video" key={title}>
              <GatsbyImage image={image} alt={title} aspectRatio={16 / 9} />
              <div className="textContainer">
                <div className="left">
                  <h3>{title}</h3>
                  <div className="smallInfo">{category}</div>
                </div>
                <div className="right">
                  <div className="smallInfo">{length}</div>
                  <div className="smallInfo">{year}</div>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
      <Footer />
    </Styled.Videos>
  )
}

export const query = graphql`
  query VideosQuery {
    allPrismicListeVideos {
      nodes {
        data {
          video {
            category
            length
            title
            url
            year
            thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    layout: FULL_WIDTH
                    formats: [JPG, WEBP]
                    breakpoints: [550, 750, 1080, 1366, 1920]
                    quality: 100
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Videos
